import axios from "axios";
import Router from "../Router";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
    return config;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    console.log(originalRequest);
    if (error.response.status === 401) {
      Router.navigate("/login");
      return Promise.reject(error);
    }
  }
);
