export default function ProductAction(props) {
  return (
    <div className="btn-group-vertical">
      <button type="button" className="btn btn-primary btn-sm">
        <i class="fa-solid fa-box-open mr-2" />
        Produce
      </button>
      <button type="button" className="btn btn-info btn-sm">
        <i class="fa-solid fa-box mr-2" />
        Dispatch
      </button>
    </div>
  );
}
