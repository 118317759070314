export default function MaterialAction(props) {
  return (
    <div className="btn-group">
      <button type="button" className="btn btn-primary btn-sm">
        <i class="fa-solid fa-plus" />
      </button>
      <button type="button" className="btn btn-info btn-sm">
        <i class="fa-solid fa-edit" />
      </button>
    </div>
  );
}
