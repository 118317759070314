import { createBrowserRouter } from "react-router-dom";

import Dashboard from "./Pages/Dashboard";
import MaterialPage from "./Pages/Material";
import PackagingPage from "./Pages/Packaging";
import ProductPage from "./Pages/Product";
import DispatchPage from "./Pages/Dispatch";

export default createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/material",
    element: <MaterialPage />,
  },
  {
    path: "/packaging",
    element: <PackagingPage />,
  },
  {
    path: "/product",
    element: <ProductPage />,
  },
  {
    path: "/dispatch",
    element: <DispatchPage />,
  },
]);
