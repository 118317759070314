import Logo from "../../Assets/logo.png";
import User from "admin-lte/dist/img/user2-160x160.jpg";

import { Link } from "react-router-dom";

import Pages from "../../Consts/Pages.json";

export default function Sidebar(props) {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <Link to="/" className="brand-link">
        <img
          src={Logo}
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">Stock Management</span>
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img src={User} className="img-circle elevation-2" alt="User" />
          </div>
          <div className="info">
            <Link to="/" className="d-block">
              {props.userName}
            </Link>
          </div>
        </div>
        <nav>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <Link
                to="/"
                className={`nav-link ${
                  props.page === Pages.Dashboard ? "active" : null
                }`}
              >
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </Link>
            </li>

            <li className="nav-header">Stock Management</li>
            <li className="nav-item">
              <Link
                to="/material"
                className={`nav-link ${
                  props.page === Pages.Material ? "active" : null
                }`}
              >
                <i className="nav-icon fa-solid fa-leaf" />
                <p>Material</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/packaging"
                className={`nav-link ${
                  props.page === Pages.Packaging ? "active" : null
                }`}
              >
                <i className="nav-icon fa-solid fa-box" />
                <p>Packaging</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/product"
                className={`nav-link ${
                  props.page === Pages.Product ? "active" : null
                }`}
              >
                <i className="nav-icon fa-solid fa-capsules" />
                <p>Product</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/dispatch"
                className={`nav-link ${
                  props.page === Pages.Dispatch ? "active" : null
                }`}
              >
                <i className="nav-icon fa-solid fa-clock-rotate-left" />
                <p>History</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
