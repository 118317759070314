import Template from "../../Template";
import Pages from "../../Consts/Pages.json";

import StockIndicator from "../../Components/StockIndicator";

export default function Dispatch(props) {
  return (
    <Template
      page={Pages.Dispatch}
      userName="Fikri Rida P"
      title="Dispatch List"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header bg-info">
                <h3 className="card-title">
                  <i className="fa-solid fa-filter mr-1" />
                  Filter Data
                </h3>
              </div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-2">
                      <label>
                        <i className="fa-solid fa-calendar-days" /> From
                      </label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="12-07-2023"
                      />
                    </div>
                    <div className="col-2">
                      <label>To</label>
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="12-07-2023"
                      />
                    </div>
                    <div className="col-3 form-group">
                      <label>Category</label>
                      <select class="form-control">
                        <option>Produce</option>
                        <option>Dispatch`</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>Date</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Stock</th>
                      <th>Batch</th>
                      <th>Expire</th>
                      <th>Supplier</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>2-10-2023 10:22</td>
                      <td>P1</td>
                      <td>
                        <a href="#">Hepato Protektor</a>
                        <span className="text-danger">
                          <i className="fa-solid fa-arrow-down mx-1" />
                          12
                        </span>
                      </td>
                      <td>76 bottle</td>
                      <td>23G01020</td>
                      <td></td>
                      <td>Stok Kramatjati</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>3-10-2023 10:00</td>
                      <td>P2</td>
                      <td>
                        <a href="#">Elekto Bio Stamino</a>
                        <span className="text-danger">
                          <i className="fa-solid fa-arrow-down mx-1" />
                          41
                        </span>
                      </td>
                      <td>51 bottle</td>
                      <td>23H01024</td>
                      <td></td>
                      <td>Stok Kramatjati</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>4-10-2023 10:15</td>
                      <td>P3</td>
                      <td>
                        <a href="#">Dakkamun</a>
                        <span className="text-danger">
                          <i className="fa-solid fa-arrow-down mx-1" />
                          27
                        </span>
                      </td>
                      <td>389 bottle</td>
                      <td>23J01025</td>
                      <td></td>
                      <td>SCI JB 9</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>5-10-2023 10:00</td>
                      <td>P2</td>
                      <td>
                        <a href="#">Elekto Bio Stamino</a>
                        <span className="text-success">
                          <i className="fa-solid fa-arrow-up mx-1" />
                          12
                        </span>
                      </td>
                      <td>51 bottle</td>
                      <td>23F04002</td>
                      <td>5-10-2024</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>5-10-2023 10:07</td>
                      <td>P4</td>
                      <td>
                        <a href="#">Cellulovit</a>
                        <span className="text-danger">
                          <i className="fa-solid fa-arrow-down mx-1" />
                          45
                        </span>
                      </td>
                      <td>73 bottle</td>
                      <td>23F04004</td>
                      <td></td>
                      <td>PT. MBM Kediri </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>6-10-2023 10:22</td>
                      <td>P1</td>
                      <td>
                        <a href="#">Hepato Protektor</a>
                        <span className="text-success">
                          <i className="fa-solid fa-arrow-up mx-1" />9
                        </span>
                      </td>
                      <td>76 bottle</td>
                      <td>23H02006</td>
                      <td>6-10-2024</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
