import Template from "../../Template";
import Pages from "../../Consts/Pages.json";

import StockIndicator from "../../Components/StockIndicator";
import ProductAction from "../../Components/ProductAction";

export default function Product(props) {
  return (
    <Template page={Pages.Product} userName="Fikri Rida P" title="Product List">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Finished Product Stock Data</h3>
                <div className="card-tools">
                  <div className="input-group input-group-sm">
                    <button type="submit" className="btn btn-sm btn-success">
                      <i className="fa-solid fa-plus mr-2" />
                      Add New Product
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Stock</th>
                      <th>Unit</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>P1</td>
                      <td>
                        <a href="#">Hepato Protektor</a>
                      </td>
                      <td>12</td>
                      <td>kg</td>
                      <td>205.000</td>
                      <td>
                        <ProductAction />
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>P2</td>
                      <td>
                        <a href="#">Elekto Bio Stamino</a>
                      </td>
                      <td>41</td>
                      <td>g</td>
                      <td>165.000</td>
                      <td>
                        <ProductAction />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>P3</td>
                      <td>
                        <a href="#">Dakkamun</a>
                      </td>
                      <td>27</td>
                      <td>g</td>
                      <td>249.750</td>
                      <td>
                        <ProductAction />
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>P4</td>
                      <td>
                        <a href="#">Cellulovit</a>
                      </td>
                      <td>45</td>
                      <td>g</td>
                      <td>199.000</td>
                      <td>
                        <ProductAction />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
