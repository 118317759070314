import Template from "../../Template";
import Pages from "../../Consts/Pages.json";

import StockIndicator from "../../Components/StockIndicator";
import ProductAction from "../../Components/ProductAction";
import MaterialAction from "../../Components/MaterialAction";

export default function Packaging(props) {
  return (
    <Template
      page={Pages.Packaging}
      userName="Fikri Rida P"
      title="Packaging List"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Packaging Material Stock Data</h3>
                <div className="card-tools">
                  <div className="input-group input-group-sm">
                    <button type="submit" className="btn btn-sm btn-success">
                      <i className="fa-solid fa-plus mr-2" />
                      Add New Packaging
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Stock</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <a href="#">Botol Hepato</a>
                      </td>
                      <td>12 pcs</td>
                      <td>
                        <MaterialAction />
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        <a href="#">Kardus Nutrichick 1,1 kg</a>
                      </td>
                      <td>41 pcs</td>
                      <td>
                        <MaterialAction />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <a href="#">Stiker EBS</a>
                      </td>
                      <td>27 pcs</td>
                      <td>
                        <MaterialAction />
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>
                        <a href="#">Stiker Nutrichick 550 g</a>
                      </td>
                      <td>45 pcs</td>
                      <td>
                        <MaterialAction />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
}
